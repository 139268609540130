import { BrandConfig, BrandData, Colors, Constants, Content } from "../../types";

const brandData: BrandData = {
  id: 5,
  title: "MAC Nifico",
};

const colors: Colors = {
  lightMain: "rgba(246, 188, 27, 0.25)",
  main: "#F6BC1B",
  white: "#fff",
  darkGrey: "#1d262f",
  primaryButton: {
    main: "#e14a4a",
    hover: "#BD3434",
    color: "white",
  },
  primaryContainedButton: {
    main: "white",
    hover: "#E14A4A",
    color: "white",
    colorHover: "white",
    borderColor: "#E14A4A",
  },
  secondaryButton: {
    main: "#E6DCC2",
    hover: "#C2BAA4",
    color: "rgba(29, 38, 47, 1)",
  },
};

const content: Content = {
  aboutUs: {
    title: "MAC Nifico",
    subtitle: "",
    text: "Cheesy goodness so amazing it is nothing short of MAC Nifico, that’s us. All we serve here is for the love of Mac & Cheese. \nOur MACSTACKS start with our savory mac and cheese then layered with our signature smoked meats like chopped brisket, with a drizzle of BBQ sauce, more shredded cheddar cheese, and green onions. We also offer any of our amazing MACSTACK combinations loaded onto baked potato casserole for an extra pleasing plate. \nBut wait there’s more! The Ultimate Stacks - are only for the most devoted mac and cheese lovers around. Our TEXAS-sized stack is built, layered, and served in our 32oz Big Yellow Cup and is nothing short of magnificent, magnanimous mac and cheese glory. Try the Ultimate Brisket Stack, which takes mac & cheese layered with chopped smoked brisket, slices of jalapeño cheddar sausage, BBQ sauce, baked beans topped with even more mac and cheese and finished with smoked bacon and green onions. MAC Nifico is mac and cheese love at it’s best.",
  },
  hero: {
    title: "MAC Nifico",
    subtitle: "For the love of mac and cheese",
    orderNowText: "Order Now",
    viewMenuText: "View Menu",
  },
  meta: {
    title: "MAC Nifico",
    description:
      "Welcome to our culinary journey! At MAC Nifico, we're not just about mac and cheese; we're about crafting experiences that resonate with your taste buds and warm your soul. Our commitment to quality ingredients, artisanal craftsmanship, and a pinch of creativity forms the heart of each dish we serve. Join us on this savory adventure and savor the taste of nostalgia.",
    image: "",
    url: "",
  },
};

const constants: Constants = {
  menuAtAGlancePdf: "brand_Menu_At_Glance.pdf",
  privacyPolicyPdf: "Mac_Nifico_Privacy_policy.pdf",
  termsAndConditionsPdf: "Mac_Nifico_T&C.pdf",
};

const brandConfig: BrandConfig = {
  brandData,
  constants,
  content,
  colors,
};

export default brandConfig;
